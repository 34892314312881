<template>
  <div>
    <img width="100%" src="../assets/images/bdform.png" />
  
      <Form :class="'form_step'+step"
            id="monform"
            :form_id="form_id"
            :server="server"
            v-on:results="getResult"
            v-on:step-up="checkStep"
            v-on:step-down="checkStep"
            v-on:form_submit_loading=loading
          />

    <div class="captcha">
      This site is protected by reCAPTCHA and the Google<br />
      <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
    <br />
    <br />
  </div>
    <br />
    <br />
  </div>
</template>
<script>
var consts = require('../config');
import axios from "axios";

export default {
  name: "form",
  data: function () {
    return {
      step: 0,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: 'Envoi en cours',
          html: 'Merci de bien vouloir patienter ...',
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading()
          }
        })
      }
    },
    checkStep() {
       this.step = this.step++
    },
    getResult(result) {
      if (result._id) {
        this.$swal.close()
        this.$router.push("/done");
      } else {
        alert('Erreur interne')
      }
    },
  },
  mounted() {},
  created: function () {}
} 
</script>